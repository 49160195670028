import styled from 'styled-components';
import InlineSVG from 'svg-inline-react';

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: 0;

  button {
    background-color: transparent;
    border: none;
    padding: ${props => props.theme.space.s2};
  }
`;

export const CloseButton = styled(InlineSVG)`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  svg {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.4rem;
    fill: ${props => props.theme.colors.capsuleGray50};
  }
`;

export const Overlay = styled.div<{ isHiddenOnLargeScreens: boolean; isShown: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${props => (props.isShown ? props.theme.opacities.overlay : props.theme.opacities.none)};
  background-color: ${props => props.theme.colors.capsuleBlue50};
  z-index: ${props => props.theme.zIndices.overlay};
  position: ${props => (props.isShown ? 'fixed' : '')};

  ${props => props.theme.mediaQueries.medium} {
    display: ${props => (props.isHiddenOnLargeScreens ? 'none' : 'block')};
  }
`;

export const Menu = styled.div<{
  hasGreenBackground: boolean;
  isHiddenOnLargeScreens: boolean;
  transitionState: 'entering' | 'entered' | 'exiting' | 'exited';
}>`
  padding: 0 ${props => props.theme.space.s6};
  position: fixed;
  display: flex;
  top: 0;
  height: 100vh;
  left: 0;
  width: 20.4rem;
  flex-direction: column;
  box-shadow: 0.2rem 0 0.4rem 0 rgba(0, 0, 0, 0.06);
  overflow-y: auto;
  z-index: ${props => props.theme.zIndices.overlayMore};
  background-color: ${props =>
    props.hasGreenBackground ? props.theme.colors.capsuleGreen00 : props.theme.colors.white};
  transform: ${({ transitionState }) => (transitionState === 'entered' ? 'translateX(0%)' : ' translateX(-100%)')};
  transition: 250ms ease-in;

  ${props => props.theme.mediaQueries.medium} {
    display: ${props => (props.isHiddenOnLargeScreens ? 'none' : 'flex')};
  }
`;

export const Logo = styled.div<{ isLogoPaddedOnLeftSide: boolean }>`
  height: 8rem;
  display: flex;
  align-items: center;
  padding-left: ${props => (props.isLogoPaddedOnLeftSide ? props.theme.space.s7 : '')};
  justify-content: ${props => (props.isLogoPaddedOnLeftSide ? '' : 'center')};
`;

export const LinkColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LinkContainer = styled.div`
  padding-bottom: ${props => props.theme.space.s5};

  &:first-child {
    padding-top: ${props => props.theme.space.s6};
  }
`;
