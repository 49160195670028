import styled from 'styled-components';

import { Container } from '../../../capsule-elements/Grid';
import { CapsuleIcon } from '../../../icons/CapsuleIcon';
import { SectionContainer } from '../../Sections/SectionContainer';

export const FooterContainer = styled(SectionContainer)`
  background-color: ${props => props.theme.colors.capsuleGreen00} !important;
  padding-top: ${props => props.theme.space.s9};
  ${props => props.theme.mediaQueries.extraLargeDown} {
    /* Chris may add to the design system later. One off for now */
    padding-top: 7.2rem;
  }
`;

export const InnerContainer = styled.div`
  position: relative;
`;

export const PillIcon = styled(CapsuleIcon)`
  position: absolute;
  bottom: -5rem;
  right: -14rem;

  transform: rotate(-10deg);
  svg {
    height: 30.8rem;
    width: 30.8rem;
  }
`;

export const NeedHelpContent = styled.div`
  margin-bottom: ${props => props.theme.space.s7};
`;

export const AppButtonsContent = styled.div`
  padding: 0;
`;

export const MobileAppLinks = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.space.s7};
  gap: 1.2rem;
  flex-wrap: wrap;
`;

export const MobileLink = styled.a`
  cursor: pointer;
`;
export const SocialMediaLink = styled.a`
  cursor: pointer;
`;

export const MobileSVG = styled(CapsuleIcon)`
  svg {
    width: 152px !important;
    height: 48px !important;

    @media screen and (max-width: 371px) {
      width: 120px !important;
    }
  }
`;

export const LeftContent = styled.div`
  ${props => props.theme.mediaQueries.medium} {
    flex: 1;
  }
`;

export const RightContent = styled.div`
  display: flex;

  ${props => props.theme.mediaQueries.medium} {
    flex: 1;
    margin-bottom: 0;
  }
`;

export const CompanyInfoContent = styled.div`
  flex: 1;
`;

export const MoreInfoContent = styled.ul`
  list-style: none;
  margin: none;
`;

export const Info = styled.li`
  :not(:last-of-type) {
    margin-bottom: 1.2rem;
  }
`;

export const SocialMediaContent = styled.div`
  flex: 1;
`;

export const SocialMediaIcons = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;
`;

export const MediaIcon = styled(CapsuleIcon)`
  padding: 0;

  svg {
    width: 24px !important;
    height: 24px !important;
  }

  margin-right: 2.5rem;
`;

export const Copyright = styled.div`
  display: block;
  margin-bottom: ${props => props.theme.space.s5};
  ${props => props.theme.mediaQueries.extraLargeDown} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: ${props => props.theme.space.s7};
  }

  ${props => props.theme.mediaQueries.smallDown} {
    margin-bottom: 16.8rem; /* Give space for bottom CTAs on mobile */
  }
`;

export const CopyrightLink = styled.span`
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-of-type):after {
    content: '\\007C';
    color: ${props => props.theme.colors.capsuleGray50};
    margin: 0 0.6rem;
    display: inline-block;
  }
`;

export const StyledContainer = styled(Container)`
  margin: ${props => props.theme.space.s9} ${props => props.theme.space.none} ${props => props.theme.space.none}
    ${props => props.theme.space.none} !important;
  padding: ${props => props.theme.space.none} !important;
`;
