import { EVENT, track } from '../../capsule-tracking/analytics';
import { getUrlPathWithPreservedQuery } from '../../utils/utils';

export const onGetStartedClickHandleRedirect = (source: string) => {
  if (typeof window !== 'undefined') {
    track(EVENT.beginOnboarding, { pagepath: window?.location?.pathname, source });

    window.location.href = getUrlPathWithPreservedQuery('/get-started');
  }
};
