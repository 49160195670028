import type { FC } from 'react';

import type { ThemeColorType } from '@capsule/consumer-design-system';

import { Typography } from '../capsule-elements/Typography';

import { Link } from './styles/commonStyles';

const CAPSULE_CALL_NUMBER = '+18889101808';
const NUMBER_FORMATTED = '(888) 910-1808';

type HelpPhoneLinkProps = {
  color?: ThemeColorType;
  type?: 'tel' | 'sms';
  onClick?: (e: any) => void;
};

export const HelpPhoneLink: FC<HelpPhoneLinkProps> = ({ color, type = 'tel', onClick }) => {
  return (
    <Link href={`${type}:${CAPSULE_CALL_NUMBER}`} onClick={onClick} color={color}>
      {NUMBER_FORMATTED}
    </Link>
  );
};

export const HelpPhone = () => {
  return (
    <Typography variant="body" marginTop={'none'}>
      Call or Text: <HelpPhoneLink />
    </Typography>
  );
};
