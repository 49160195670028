import type { ThemeColorType, ThemeSpaceType } from '@capsule/consumer-design-system';

import styled, { css } from 'styled-components';

const baseHeader = css<{ color?: ThemeColorType; marginBottom?: ThemeSpaceType }>`
  color: ${props => (props.color ? props.theme.colors[props?.color] : props.theme.colors.capsuleBlue50)};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: ${props => props.theme.fontWeights.bold};
  padding: ${props => props.theme.space.none};
  margin: ${props => props.theme.space.none};
  margin-bottom: ${props => (props.marginBottom ? props.theme.space[props.marginBottom] : 'initial')};
`;

export const DisplayXXXL = styled.h1`
  ${baseHeader}
  font-size: ${props => props.theme.fontSizes.displayXXXL};
  line-height: ${props => props.theme.lineHeights.displayXXXL};
`;

export const DisplayXXL = styled.h2`
  ${baseHeader}
  font-size: ${props => props.theme.fontSizes.displayXXL};
  line-height: ${props => props.theme.lineHeights.displayXXL};
`;

export const DisplayXL = styled.h3`
  ${baseHeader}
  font-size: ${props => props.theme.fontSizes.displayXL};
  line-height: ${props => props.theme.lineHeights.displayXL};
`;

export const H1 = styled.h1`
  ${baseHeader}
  font-size: ${props => props.theme.fontSizes.headerOne};
  line-height: ${props => props.theme.lineHeights.headerOne};
  color: ${props => (props?.color ? props.theme.colors[props?.color] : props.theme.colors.capsuleBlue50)};
`;

export const H2 = styled.h2`
  ${baseHeader}
  font-size: ${props => props.theme.fontSizes.headerTwo};
  line-height: ${props => props.theme.lineHeights.headerTwo};
`;

export const H3 = styled.h3`
  ${baseHeader}
  font-size: ${props => props.theme.fontSizes.headerThree};
  line-height: ${props => props.theme.lineHeights.headerThree};
`;

export const H4 = styled.h4`
  ${baseHeader}
  font-size: ${props => props.theme.fontSizes.headerFour};
  line-height: ${props => props.theme.lineHeights.headerFour};
`;

const baseBody = css<{ color: ThemeColorType; marginBottom: ThemeSpaceType; marginTop: ThemeSpaceType }>`
  font-family: ${props => props.theme.fonts.circular};
  color: ${props => (props?.color ? props.theme.colors[props?.color] : props.theme.colors.capsuleBlue50)};
  /* TODO (Najja): Using the theme to set the font-weight removes the font-family. An investigation should be conducted. */
  /* font-weight: ${props => props.theme.fontWeights.book}; */
  margin-bottom: ${props =>
    props.theme.space[props.marginBottom] ? props.theme.space[props.marginBottom] : 'initial'};
`;

export const Body = styled.p`
  ${baseBody}
  font-family: ${props => props.theme.fonts.circular} !important;
  font-size: ${props => props.theme.fontSizes.body};
  font-weight: ${props => props.theme.fontWeights.book};
  line-height: ${props => props.theme.lineHeights.body};
  ${props => props.theme.space[props.marginBottom] && `margin-bottom: ${props.theme.space[props.marginBottom]};`}
  margin-top: ${props => props.theme.space[props.marginTop]};
`;

export const Small = styled.p`
  ${baseBody}
  font-size: ${props => props.theme.fontSizes.small};
  line-height: ${props => props.theme.lineHeights.small};
  ${props => props.theme.space[props.marginBottom] && `margin-bottom: ${props.theme.space[props.marginBottom]};`}
`;

const baseCallout = css<{ color: ThemeColorType; marginBottom: ThemeSpaceType }>`
  color: ${props => props.theme.colors.capsuleBlue50};
  text-transform: ${props => props.theme.textTransforms.allCaps};
  line-height: ${props => props.theme.lineHeights.callout};
  letter-spacing: ${props => props.theme.letterSpacings.one};
  font-weight: ${props => props.theme.fontWeights.semiBold};
  margin-bottom: ${props =>
    props.theme.space[props.marginBottom] ? props.theme.space[props.marginBottom] : 'initial'};
`;

export const Overline = styled.p`
  ${baseCallout}
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.capsuleBlue50)};
  margin-bottom: ${props =>
    props.theme.space[props.marginBottom] ? props.theme.space[props.marginBottom] : 'initial'};
`;

export const Callout = styled.p`
  ${baseCallout}
  font-family: ${props => props.theme.fonts.mercury};
  font-size: ${props => props.theme.fontSizes.callout};
  color: ${props => {
    if (props.color) {
      return props.theme.colors[props.color];
    }
    return props.theme.colors.capsuleBlue50;
  }};
`;

export const CalloutSmall = styled.p`
  ${baseCallout}
  font-family: ${props => props.theme.fonts.mercury};
  font-size: ${props => props.theme.fontSizes.calloutSmall};
  font-weight: ${props => props.theme.fontWeights.roman};
`;

export const Quote = styled.h2`
  color: ${props => props.theme.colors.capsuleBlue50};
  font-family: ${props => props.theme.fonts.mercury};
  font-weight: ${props => props.theme.fontWeights.book};
  font-size: 4.8rem;
  text-align: center;
  line-height: 6rem;
  ${props => props.theme.mediaQueries.smallDown} {
    line-height: 3rem;
  }
`;
