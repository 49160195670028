import type { FC } from 'react';
import { useCallback, useEffect } from 'react';

import { Button } from '@capsule/consumer-design-system';

import Swipe from 'react-easy-swipe';
import FocusLock from 'react-focus-lock';
import { Transition } from 'react-transition-group';

import { Link, NavLink } from '../../../capsule-elements/Link';
import { EVENT, track } from '../../../capsule-tracking/analytics';
import { onGetStartedClickHandleRedirect } from '../../../common/handlers/ClickHandlers';
import { CapsuleIcon } from '../../../icons/CapsuleIcon';
import Cross from '../../../icons/cross.svg';
import { getUrlPathWithPreservedQuery } from '../../../utils/utils';

import { CloseButton, CloseButtonContainer, LinkColumn, LinkContainer, Logo, Menu, Overlay } from './SideMenuStyles';

interface FocusLockAndEscapeProps {
  handleEscapePressed: () => void;
}

const FocusLockAndEscape: FC<FocusLockAndEscapeProps> = ({ children, handleEscapePressed }) => {
  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleEscapePressed();
      }
    },
    [handleEscapePressed]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return <FocusLock noFocusGuards>{children}</FocusLock>;
};

const generateLinks = () => [
  {
    displayText: 'How it works',
    href: '/how-it-works',
  },
  {
    displayText: 'About us',
    href: '/about',
  },
  {
    displayText: 'For doctors',
    href: '/doctors',
  },
  {
    displayText: 'Questions',
    href: '/questions',
  },
  {
    displayText: 'Sign in',
    href: '/auth/login',
  },
];

const goToOnboarding = () => {
  onGetStartedClickHandleRedirect('Navigation Bar');
};

export interface SideMenuProps {
  isShown: boolean;
  onClose: () => void;
  className?: string;
  logoTo?: string;
  hasGreenBackground?: boolean;
  isLogoPaddedOnLeftSide?: boolean;
  isHiddenOnLargeScreens?: boolean;
}

/**
 * This encapsulates a component which displays a side-menu that can slide out.
 *
 * State is managed externally.
 *
 * This component does not take care of anything but creating the menu, making sure it animates correctly,
 * and putting up an overlay that will trigger onClose when clicked.
 *
 * @param {bool} isShown Should this menu be shown or not?
 * @param {bool} hasGreenBackground Should the menu have a green background?
 * @param {bool} isLogoPaddedOnLeftSide Is the logo centered?
 * @param {bool} isHiddenOnLargeScreens Should the menu be hidden on large screens?
 * @param {function(e)} onClose An action to handle this menu being closed such as clicking on the overlay
 * @param {String?} className A className to be appended
 * @param {Node} children The children to render inside the menu
 * @return {Node}
 * @constructor
 */

export const SideMenu: FC<SideMenuProps> = ({
  isShown,
  onClose,
  className,
  hasGreenBackground = false,
  isLogoPaddedOnLeftSide = false,
  isHiddenOnLargeScreens = false,
  logoTo,
}) => {
  const links = generateLinks();

  return (
    <FocusLockAndEscape handleEscapePressed={onClose}>
      <Swipe onSwipeLeft={onClose}>
        <div className={className}>
          <Overlay
            onClick={onClose}
            isHiddenOnLargeScreens={isHiddenOnLargeScreens}
            isShown={isShown}
            data-testid="overlay"
          />
          <Transition
            in={isShown}
            timeout={isShown ? 0 : 250}
            unmountOnExit
            mountOnEnter
            addEndListener={(node: HTMLElement, done: () => void) => {
              node.addEventListener('transitionend', done, false);
            }}
          >
            {(transitionState: 'entering' | 'entered' | 'exiting' | 'exited') => (
              <Menu
                transitionState={transitionState}
                isHiddenOnLargeScreens={isHiddenOnLargeScreens}
                hasGreenBackground={hasGreenBackground}
                id="side-menu"
                data-testid="menu"
                role="navigation"
                aria-label="Side Navigation Menu"
              >
                <>
                  <CloseButtonContainer>
                    <button id="close-menu" type="button" aria-label="Close Menu" onClick={onClose}>
                      <CloseButton src={Cross} />
                    </button>
                  </CloseButtonContainer>
                  {logoTo ? (
                    <Logo isLogoPaddedOnLeftSide={isLogoPaddedOnLeftSide}>
                      <Link
                        href={logoTo}
                        onClick={() => {
                          track(EVENT.beginOnboarding, {
                            pagepath: window?.location?.pathname,
                            source: 'Navigation Bar',
                          });
                          onClose();
                        }}
                      >
                        <CapsuleIcon iconName={'CapsuleLogo'} type="logo" />
                      </Link>
                    </Logo>
                  ) : (
                    <Logo isLogoPaddedOnLeftSide={isLogoPaddedOnLeftSide}>
                      <CapsuleIcon iconName={'CapsuleLogo'} type="logo" />
                    </Logo>
                  )}
                </>
                <Button variant="contained-primary-lg" onClick={goToOnboarding}>
                  Get started
                </Button>
                <LinkColumn>
                  {links.map(({ displayText, href }) => (
                    <LinkContainer key={href}>
                      <NavLink href={getUrlPathWithPreservedQuery(href)} color="capsuleBlue50">
                        {displayText}
                      </NavLink>
                    </LinkContainer>
                  ))}
                </LinkColumn>
              </Menu>
            )}
          </Transition>
        </div>
      </Swipe>
    </FocusLockAndEscape>
  );
};
