import { useEffect, useState } from 'react';
import type { FC } from 'react';

import Head from 'next/head';

import { StickyContainer } from 'react-sticky';

import { NAV_HEIGHT, NAVBAR_STICKY_OFFSET } from '../../consts';

import { Footer } from './Footer';
import { LogoTheme, NavTheme } from './Header/constants';
import { Header } from './Header/Header';
import { FlexCenterContainer, MaxWidthContainer } from './styles';

export enum ButtonVariant {
  LIGHT_BUTTON_VARIANT = 'contained-primary-lg',
  DARK_BUTTON_VARIANT = 'dark-outline-lg',
}

interface LayoutProps {
  logoTheme?: LogoTheme;
  logoEast?: LogoTheme;
  maxPageWidth?: boolean;
  navBuffer?: number;
  navOpacity?: number;
  navTheme?: NavTheme;
}

export const Layout: FC<LayoutProps> = ({
  children,
  logoTheme = LogoTheme.blue,
  logoEast = LogoTheme.green,
  maxPageWidth = true,
  navBuffer = 20,
  navOpacity = 1,
  navTheme = NavTheme.transparent,
}) => {
  const window = global.window ? global.window : null;
  const [currNavTheme, setNavTheme] = useState(navTheme);
  const [currNavOpacity, setNavOpacity] = useState(navOpacity);
  const [currLogoTheme, setLogoTheme] = useState(logoTheme);
  const [currLogoEast, setLogoEast] = useState(logoEast);
  const [currButtonVariant, setCurrButtonVariant] = useState(ButtonVariant.DARK_BUTTON_VARIANT);
  const BUFFER = navBuffer;

  useEffect(() => {
    const getNavThemeFromScroll = () => {
      const scroll = window?.scrollY || window?.pageYOffset || 0;
      const stickyanchor = document.querySelector('#stickyanchor') as HTMLDivElement;
      const offsetHeight = stickyanchor?.offsetTop;

      if (typeof window !== undefined) {
        if (scroll + BUFFER > offsetHeight) {
          setNavTheme(NavTheme.white);
          setLogoTheme(LogoTheme.blue);
          setCurrButtonVariant(ButtonVariant.LIGHT_BUTTON_VARIANT);
          setLogoEast(LogoTheme.green);
        } else {
          setNavTheme(navTheme);
          setLogoTheme(logoTheme);
          setCurrButtonVariant(ButtonVariant.DARK_BUTTON_VARIANT);
          setLogoEast(logoEast);
        }

        if (scroll + BUFFER - offsetHeight > 0 && scroll + BUFFER - offsetHeight < 100) {
          const ratio = (scroll + BUFFER - offsetHeight) / NAV_HEIGHT;
          setNavOpacity(ratio);
        } else {
          setNavOpacity(1);
        }
      }
    };

    getNavThemeFromScroll();

    const handleScroll = () => {
      getNavThemeFromScroll();
    };

    if (window) window.addEventListener('scroll', handleScroll);

    return () => {
      if (window) window.removeEventListener('scroll', handleScroll);
    };
  }, [BUFFER, logoEast, logoTheme, navTheme, window]);

  return (
    <>
      <Head>
        <title>Capsule | The smarter, simpler, kinder pharmacy</title>
        <meta property="og:title" content="Capsule | The smarter, simpler, kinder pharmacy" key="title" />
      </Head>
      <StickyContainer>
        <Header
          logoTheme={currLogoTheme}
          logoEast={currLogoEast}
          navTheme={currNavTheme}
          stickyTopOffset={NAVBAR_STICKY_OFFSET}
          navOpacity={currNavOpacity}
          buttonVariant={currButtonVariant}
        />

        {maxPageWidth ? (
          <FlexCenterContainer>
            <MaxWidthContainer>{children}</MaxWidthContainer>
          </FlexCenterContainer>
        ) : (
          children
        )}
        <Footer />
      </StickyContainer>
    </>
  );
};
