import type { FC } from 'react';

import type { ThemeColorType } from '@capsule/consumer-design-system';

import { FlexCenterContainer, MaxWidthContainer } from './SectionContainerStyles';

export interface SectionContainerProps {
  backgroundColor?: ThemeColorType;
  borderBottom?: boolean;
  hasPadding?: boolean;
  isOffsetForHeader?: boolean;
}

export const SectionContainer: FC<SectionContainerProps> = ({
  backgroundColor,
  borderBottom,
  children,
  hasPadding = true,
  isOffsetForHeader = false,
}) => {
  return (
    <FlexCenterContainer backgroundColor={backgroundColor} isOffsetForHeader={isOffsetForHeader}>
      <MaxWidthContainer borderBottom={borderBottom} hasPadding={hasPadding}>
        {children}
      </MaxWidthContainer>
    </FlexCenterContainer>
  );
};
