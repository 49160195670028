// TODO should probs export each component instead of one component that takes a src to avoid bundling all svg files
// Have to figure best way to style them rather than the old way of a class / type for each icon
import type { FC, HTMLAttributes } from 'react';

import AppStore from './AppStore.svg';
import CapsuleLogo from './capsule-logo.svg';
import CircleCheck from './CircleCheck.svg';
import CirclePill from './CirclePill.svg';
import Close from './Close.svg';
import Facebook from './Facebook.svg';
import FooterPill from './FooterPill.svg';
import FreeDelivery from './FreeDelivery.svg';
import GoldStar from './GoldStar.svg';
import GooglePlay from './GooglePlay.svg';
import GreenBackArrow from './green-back-arrow.svg';
import GreenForwardArrow from './green-forward-arrow.svg';
import Hamburger from './Hamburger.svg';
import Instagram from './Instagram.svg';
import InsuranceAccepted from './InsuranceAccepted.svg';
import Logo from './Logo.svg';
import ObliqueTallPill from './ObliqueTallPill.svg';
import OldPharmacy from './OldPharmacy.svg';
import PayCopay from './PayCopay.svg';
import PillTriangle from './pill-triangle.svg';
import RedHeart from './red-heart.svg';
import RightArrow from './RightArrow.svg';
import { StyledIcon } from './styles';
import TallPill from './TallPill.svg';
import TrianglePill from './TrianglePill.svg';
import TrianglePillRotated from './TrianglePillRotated.svg';
import Twitter from './Twitter.svg';

const iconSrc = {
  CapsuleLogo,
  Facebook,
  GooglePlay,
  Instagram,
  AppStore,
  Twitter,
  Hamburger,
  FooterPill,
  RedHeart,
  PillTriangle,
  TallPill,
  CirclePill,
  TrianglePill,
  RightArrow,
  CircleCheck,
  GoldStar,
  GreenForwardArrow,
  GreenBackArrow,
  FreeDelivery,
  InsuranceAccepted,
  PayCopay,
  Close,
  Logo,
  OldPharmacy,
  TrianglePillRotated,
  ObliqueTallPill,
};

export interface CapsuleIconProps {
  iconName: keyof typeof iconSrc;

  /**
   * @deprecated
   * TODO: Remove. Nobody should be passing click handlers to non-interactive elements.
   */
  onClick?: HTMLAttributes<HTMLDivElement>['onClick'];
  type?: 'appButton' | 'logo'; //enum
  className?: string;
}

export const CapsuleIcon: FC<CapsuleIconProps> = ({ iconName, onClick, type, className }) => {
  return <StyledIcon {...{ src: iconSrc[iconName], onClick, type, className }} />;
};
