import styled from 'styled-components';

import type { ThemeType } from 'src/styles/theme';

import { NAV_HEIGHT } from '../../../consts';

import { LogoTheme, NavTheme } from './constants';

const BOX_SHADOW = 'rgb(0 0 0 / 6%) 0px 0.2rem 0.4rem 0px';

interface GetThemeProps {
  navTheme?: string;
  theme: ThemeType;
}

const getThemeColor = (props: GetThemeProps) =>
  props.navTheme === NavTheme.transparent ? props.theme.colors.transparent : props.theme.colors.white;

const getInverseThemeColor = (props: GetThemeProps) =>
  props.navTheme === NavTheme.transparent ? props.theme.colors.white : props.theme.colors.capsuleBlue50;

export const HeaderWrapper = styled.div<{ navTheme: NavTheme }>`
  position: absolute;
  z-index: ${props => props.theme.zIndices.overlay};
  box-shadow: ${props => (props.navTheme === NavTheme.transparent ? `none` : BOX_SHADOW)};
  width: 100%;
`;

export const NavWrapper = styled.div<{ logoEast: LogoTheme; logoTheme: LogoTheme; navTheme: NavTheme }>`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  background-color: ${props => getThemeColor(props)};
  color: ${props => getInverseThemeColor(props)};
  box-shadow: ${props => (props.navTheme === NavTheme.transparent ? `none` : props.theme.shadows.dp6)};
  z-index: ${props => props.theme.zIndices.overlay};
  height: ${NAV_HEIGHT}px;
  .capsuleLogoTextAlternateColor {
    fill: ${props => getInverseThemeColor(props)};
  }
  .capsuleLogoAlternateColor {
    fill: ${props =>
      props.logoTheme === LogoTheme.white ? props.theme.colors.white : props.theme.colors.capsuleBlue50} !important;
  }
  .capsuleLogoEast {
    fill: ${props =>
      props.logoEast === LogoTheme.white ? props.theme.colors.white : props.theme.colors.capsuleGreen50} !important;
  }
  a {
    color: currentColor !important;
  }
`;

// Desktop Nav Components

export const DesktopLeftNav = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  margin-left: ${props => props.theme.space.s7};

  > a:not(:first-of-type) {
    margin-left: ${props => props.theme.space.s7};
  }
  > a:first-of-type {
    margin-left: ${props => props.theme.space.s9};
  }

  ${props => props.theme.mediaQueries.XXLargeDown} {
    > a:not(:first-of-type) {
      margin-left: ${props => props.theme.space.s6};
    }
    > a:first-of-type {
      margin-left: ${props => props.theme.space.s7};
    }
  }

  ${props => props.theme.mediaQueries.extraLargeDown} {
    display: none;
  }
`;

export const DesktopRightNav = styled.div`
  display: flex;
  align-items: center;
  flex-direction: flex-end;
  flex-wrap: nowrap;
  margin-right: ${props => props.theme.space.s7};

  a:not(:last-child) {
    margin-right: ${props => props.theme.space.s7};
  }

  ${props => props.theme.mediaQueries.XXLargeDown} {
    > a:not(:last-child) {
      margin-right: ${props => props.theme.space.s6};
    }
  }

  ${props => props.theme.mediaQueries.extraLargeDown} {
    display: none;
  }
`;

export const MobileLeftNav = styled.div`
  flex: 1 1 50%;
  align-items: center;
  flex-wrap: nowrap;
  display: none;
  ${props => props.theme.mediaQueries.extraLargeDown} {
    display: flex;
    justify-content: space-between;
  }
`;

export const HamburgerContainer = styled.div<{ navTheme: string }>`
  padding-left: ${props => props.theme.space.s5};
  cursor: pointer;
  button {
    background-color: transparent;
    border: 0;
  }
  svg {
    path {
      fill: ${props => getInverseThemeColor(props)};
    }
  }
`;

const HAMBURGER_WIDTH = 50;
export const LogoContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  ${props => props.theme.mediaQueries.extraLargeDown} {
    padding-right: ${HAMBURGER_WIDTH}px; /* Adjustment to center logo */
    margin: auto;
  }
`;

export const HeaderNavLink = styled.a`
  display: inline-block;
  position: relative;
  white-space: nowrap;
  height: 4.2rem;
  line-height: 4.2rem;
  vertical-align: middle;
  text-decoration: none !important;
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.callout};
  font-family: ${props => props.theme.fonts.mercury};
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.letterSpacings.one};

  /* Underline behavior */
  &:after {
    display: none;
  }
  &:hover:after {
    height: 0.1rem;
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0.8rem;
    display: block;
    background-color: ${props => getThemeColor(props)};
  }
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #879db5;
  opacity: 0.8;

  color: ${props => props.theme.colors.white};
  font-size: 1.6rem;
  font-weight: 700;
  font-family: 'Circular-Pro';

  min-height: 5rem;
  padding: 1rem 1rem;
  text-align: center;

  ${props => props.theme.mediaQueries.small} {
    padding: 0.4rem 2rem;
  }
`;
