export enum NavTheme {
  blue = 'blue',
  white = 'white',
  transparent = 'transparent',
}

export enum LogoTheme {
  white = 'white',
  blue = 'blue',
  green = 'green',
}

export enum Variant {
  base = 'base',
  simple = 'simple',
}
