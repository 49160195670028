import { useMediaQuery } from '@react-hookz/web';

import {
  about,
  blog,
  careers,
  doctors,
  external,
  hipaa,
  press,
  privacy,
  termsAndConditions,
} from 'src/app/router/paths';
import { Col, Row, Visible } from 'src/landing-pages/capsule-elements/Grid';
import { SimpleLink } from 'src/landing-pages/capsule-elements/Link';
import { Typography } from 'src/landing-pages/capsule-elements/Typography';
import { EVENT, track } from 'src/landing-pages/capsule-tracking/analytics';
// TODO HelpPhone should be moved to components
import { HelpPhoneLink } from 'src/landing-pages/common/HelpPhone';
import { CAPSULE_HELP_EMAIL } from 'src/landing-pages/consts';
import { getUrlPathWithPreservedQuery } from 'src/landing-pages/utils/utils';
import { Breakpoint } from 'src/styles/theme';

import {
  AppButtonsContent,
  CompanyInfoContent,
  Copyright,
  CopyrightLink,
  FooterContainer,
  Info,
  InnerContainer,
  LeftContent,
  MediaIcon,
  MobileAppLinks,
  MobileLink,
  MobileSVG,
  MoreInfoContent,
  NeedHelpContent,
  PillIcon,
  RightContent,
  SocialMediaContent,
  SocialMediaIcons,
  SocialMediaLink,
  StyledContainer,
} from './styles';

export const Footer = () => {
  const md = useMediaQuery(Breakpoint.medium);

  return (
    <FooterContainer backgroundColor="capsuleGreen00">
      <InnerContainer>
        <StyledContainer>
          <Row>
            <Col xl={4} md={5} xs={12}>
              <LeftContent>
                <NeedHelpContent>
                  <Typography variant={md ? 'h3' : 'h4'} marginBottom={'s5'}>
                    Need help?
                  </Typography>
                  <Typography variant="body" marginBottom={'s3'}>
                    Call or text us <HelpPhoneLink color={'capsuleGreen80'} />
                  </Typography>
                  <Typography variant="body">
                    Email us{' '}
                    <SimpleLink href={`mailto:${CAPSULE_HELP_EMAIL}`} color={'capsuleGreen80'}>
                      {CAPSULE_HELP_EMAIL}
                    </SimpleLink>
                  </Typography>
                </NeedHelpContent>

                <AppButtonsContent>
                  <Typography variant={md ? 'h3' : 'h4'} marginBottom={'s5'}>
                    Get the app
                  </Typography>
                  <MobileAppLinks>
                    <MobileLink
                      href={external.appStore}
                      aria-label="Download on the iOS App Store"
                      onClick={() => {
                        track(EVENT.externalLinkClicked, { externalLinkDestination: external.appStore });
                      }}
                    >
                      <MobileSVG iconName={'AppStore'} type={'appButton'} />
                    </MobileLink>

                    <MobileLink
                      href={external.googlePlay}
                      aria-label="Download on the Google Play Store"
                      onClick={() => {
                        track(EVENT.externalLinkClicked, { externalLinkDestination: external.googlePlay });
                      }}
                    >
                      <MobileSVG iconName={'GooglePlay'} />
                    </MobileLink>
                  </MobileAppLinks>
                </AppButtonsContent>
              </LeftContent>
            </Col>
            <Col xl={8} md={7} xs={12}>
              <RightContent>
                <CompanyInfoContent>
                  <Typography variant={md ? 'h3' : 'h4'} marginBottom={'s5'}>
                    Company info
                  </Typography>
                  <MoreInfoContent>
                    <Info>
                      <SimpleLink href={getUrlPathWithPreservedQuery(about.route)}>About us</SimpleLink>
                    </Info>
                    <Info>
                      <SimpleLink href={getUrlPathWithPreservedQuery(blog.route)} target="_blank">
                        Blog
                      </SimpleLink>
                    </Info>
                    <Info>
                      <SimpleLink href={getUrlPathWithPreservedQuery(careers.route)} target="_blank">
                        Careers
                      </SimpleLink>
                    </Info>
                    <Info>
                      <SimpleLink href={getUrlPathWithPreservedQuery(doctors.route)}>For doctors</SimpleLink>
                    </Info>
                    <Info>
                      <SimpleLink href={getUrlPathWithPreservedQuery(press.route)} target="_blank">
                        Press
                      </SimpleLink>
                    </Info>
                  </MoreInfoContent>
                </CompanyInfoContent>
                <SocialMediaContent>
                  <Typography variant={md ? 'h3' : 'h4'} marginBottom={'s5'}>
                    Social Media
                  </Typography>
                  <SocialMediaIcons>
                    <SocialMediaLink
                      href={external.facebook}
                      aria-label="Go to Facebook page"
                      onClick={() => track(EVENT.externalLinkClicked, { externalLinkDestination: external.facebook })}
                    >
                      <MediaIcon iconName={'Facebook'} />
                    </SocialMediaLink>
                    <SocialMediaLink
                      href={external.instagram}
                      aria-label="Go to Instagram page"
                      onClick={() => track(EVENT.externalLinkClicked, { externalLinkDestination: external.instagram })}
                    >
                      <MediaIcon iconName={'Instagram'} />
                    </SocialMediaLink>
                    <SocialMediaLink
                      href={external.twitter}
                      aria-label="Go to Twitter page"
                      onClick={() => track(EVENT.externalLinkClicked, { externalLinkDestination: external.twitter })}
                    >
                      <MediaIcon iconName={'Twitter'} />
                    </SocialMediaLink>
                  </SocialMediaIcons>
                </SocialMediaContent>
              </RightContent>
            </Col>
          </Row>
          <Row>
            <Col>
              <Copyright>
                <Typography variant="small" marginBottom={'s2'}>
                  © {new Date().getFullYear()} Capsule Corporation
                </Typography>
                <div>
                  <CopyrightLink>
                    <SimpleLink small href={getUrlPathWithPreservedQuery(termsAndConditions.route)}>
                      Terms of Service
                    </SimpleLink>
                  </CopyrightLink>
                  <CopyrightLink>
                    <SimpleLink small href={getUrlPathWithPreservedQuery(privacy.route)}>
                      Privacy Policy
                    </SimpleLink>
                  </CopyrightLink>
                  <CopyrightLink>
                    <SimpleLink small href={getUrlPathWithPreservedQuery(hipaa.route)}>
                      HIPAA Policy
                    </SimpleLink>
                  </CopyrightLink>
                </div>
              </Copyright>
            </Col>
          </Row>
        </StyledContainer>
        <Visible md lg xl xxl>
          <PillIcon iconName="FooterPill" />
        </Visible>
      </InnerContainer>
    </FooterContainer>
  );
};
