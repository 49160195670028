import {
  Accordion as AccessibleAccordion,
  AccordionItemButton as AccessibleButton,
  AccordionItemPanel as AccessiblePanel,
} from 'react-accessible-accordion';
import styled, { css } from 'styled-components';

import type { ThemeSpaceType } from 'src/styles/theme';

export const StyledAccessibleAccordion = styled(AccessibleAccordion)<{ $marginBottom?: ThemeSpaceType }>`
  ${props => (props.$marginBottom ? `margin-bottom: ${props.theme.space[props.$marginBottom]};` : '')}
`;

const arrowOffset = '3.5rem';
export const AccordionItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: ${arrowOffset}; /* leave space for abs positioned arrow */
`;

export const AccordionItemButton = styled(AccessibleButton)<{ $hasInitialBorderTop: boolean }>`
  /* Prevent browsers from highlighting */
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* stylelint-enable property-no-vendor-prefix */

  &.h2 {
    font-size: ${props => props.theme.fontSizes.headerTwo} !important;
  }
  cursor: pointer;

  padding: ${props => props.theme.space.s7} 0;

  ${props => props.theme.mediaQueries.largeDown} {
    padding: ${props => props.theme.space.s5} 0;
  }

  ${props =>
    props.$hasInitialBorderTop
      ? css`
          border-top: 1px solid ${props => props.theme.colors.capsuleGray10};
        `
      : css`
          &:not(:first-of-type) {
            border-top: 1px solid ${props => props.theme.colors.capsuleGray10};
          }
        `}
  &:last-of-type {
    border-bottom: 1px solid ${props => props.theme.colors.capsuleGray10};
  }
`;

export const AccordionArrow = styled.div<{ $expanded: boolean }>`
  width: 2.4rem;
  height: 1.2rem;
  position: absolute;
  top: calc(50% - 0.6rem);

  left: 0;
  transform: ${props => (props.$expanded ? 'rotate(0deg)' : 'rotate(-90deg)')};
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  &:before {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: ${props => props.theme.colors.capsuleGreen60};
    content: '';
    left: 4px;
    transform: rotate(45deg);
  }
  &:after {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: ${props => props.theme.colors.capsuleGreen60};
    content: '';
    right: 4px;
    transform: rotate(-45deg);
  }
`;

export const AccordionItemPanel = styled(AccessiblePanel)<{ $expanded: boolean }>`
  @keyframes accordion-fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  animation: accordion-fadein 0.5s ease-in;
  margin-top: ${props => props.theme.space.s3};
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  height: ${props => (props.$expanded ? 'auto' : 0)};
  padding-left: ${arrowOffset}; /* leave space for abs positioned arrow */
`;
