import type { FC, MouseEvent } from 'react';

import { Typography } from '../capsule-elements/Typography';

import { Link } from './styles/commonStyles';

const CAPSULE_HELP_EMAIL = 'help@capsule.com';

interface HelpEmailProps {
  onClick: (e: MouseEvent) => void;
}

export const HelpEmailLink: FC<HelpEmailProps> = ({ onClick }) => (
  <Link href={`mailto:${CAPSULE_HELP_EMAIL}`} onClick={onClick}>
    {CAPSULE_HELP_EMAIL}
  </Link>
);

export const HelpEmail: FC<HelpEmailProps> = ({ onClick }) => {
  return (
    <Typography variant="body" marginBottom={'s1'}>
      E-mail: <HelpEmailLink onClick={onClick} />
    </Typography>
  );
};
