import type { ThemeColorType } from '@capsule/consumer-design-system';

import styled from 'styled-components';

import { MAX_WIDTH_CONTAINER, NAV_HEIGHT } from 'src/consts';

export const MaxWidthContainer = styled.div<{ borderBottom?: boolean; hasPadding?: boolean }>`
  max-width: ${MAX_WIDTH_CONTAINER};
  width: 100%;
  position: relative;
  border-bottom: ${props => (props.borderBottom ? `1px solid ${props.theme.colors.capsuleGray10}` : 'none')};
  ${props => props.theme.mediaQueries.extraLargeDown} {
    margin-left: ${props => (props.hasPadding ? props.theme.space.s10 : `none`)};
    margin-right: ${props => (props.hasPadding ? props.theme.space.s10 : `none`)};
  }
  ${props => props.theme.mediaQueries.largeDown} {
    margin-left: ${props => (props.hasPadding ? props.theme.space.s8 : `none`)};
    margin-right: ${props => (props.hasPadding ? props.theme.space.s8 : `none`)};
  }
  ${props => props.theme.mediaQueries.smallDown} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const FlexCenterContainer = styled.div<{ backgroundColor?: ThemeColorType; isOffsetForHeader: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: ${props =>
    props.backgroundColor ? props.theme.colors[props.backgroundColor] : props.theme.colors.white};
  padding: ${props => props.theme.space.none} ${props => props.theme.space.s5};
  padding-top: ${props => (props.isOffsetForHeader ? NAV_HEIGHT : 0)}px;
`;
