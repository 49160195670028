import styled from 'styled-components';

import { MAX_WIDTH_CONTAINER } from 'src/consts';

export const MaxWidthContainer = styled.div`
  max-width: ${MAX_WIDTH_CONTAINER};
  width: 100%;
`;

export const FlexCenterContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
`;
