import type { ThemeColorType } from '@capsule/consumer-design-system';

import styled from 'styled-components';

import { Typography } from '../../capsule-elements/Typography';

export const Link = styled.a<{ color?: ThemeColorType }>`
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme?.colors.capsuleGreen60)};
  cursor: pointer;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

export const TextContent = styled.div`
  height: 100%;
  position: relative;
`;

export const HeaderText = styled(Typography)`
  white-space: nowrap;

  ${props => props.theme.mediaQueries.largeDown} {
    white-space: nowrap;
    font-size: 5.2rem !important;
    line-height: 5.6rem !important;
  }
`;

export const BodyWrapper = styled.div`
  margin-top: ${props => props.theme.space.s5};

  ${props => props.theme.mediaQueries.smallDown} {
    margin-top: ${props => props.theme.space.s3};
  }
`;

export const BodyText = styled(Typography)`
  font-weight: ${props => props.theme.fontWeights.book};
  max-width: 55rem;

  ${props => props.theme.mediaQueries.extraLargeDown} {
    max-width: 42rem;
  }

  ${props => props.theme.mediaQueries.largeDown} {
    max-width: 55rem;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 3.2rem;

  ${props => props.theme.mediaQueries.small} {
    margin-top: ${props => props.theme.space.s7};
    max-width: 26.5rem;
  }
`;
