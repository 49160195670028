import type { FC, MouseEvent } from 'react';
import { useEffect, useState } from 'react';

import { Button, ScreenReaderOnly } from '@capsule/consumer-design-system';

import { Sticky } from 'react-sticky';

import type { ButtonVariant } from '..';
import { NAV_HEADER } from '../../../../consts/testIDs';
import { MarkdownCopy } from '../../../capsule-elements/MarkdownCopy';
import { EVENT, track } from '../../../capsule-tracking/analytics';
import { onGetStartedClickHandleRedirect } from '../../../common/handlers/ClickHandlers';
import { CapsuleIcon } from '../../../icons/CapsuleIcon';
import { getUrlPathWithPreservedQuery } from '../../../utils/utils';

import { LogoTheme, NavTheme, Variant } from './constants';
import {
  Banner,
  DesktopLeftNav,
  DesktopRightNav,
  HamburgerContainer,
  HeaderNavLink,
  HeaderWrapper,
  LogoContainer,
  MobileLeftNav,
  NavWrapper,
} from './HeaderStyles';
import { SideMenu } from './SideMenu';

const IS_MOBILE = false;

interface HeaderProps {
  variant?: Variant; // Navigation variant enum, defaults to base
  stickyTopOffset?: number; // Defaults to 0, offset for scrolling transition
  navOpacity?: number; // Defaults to 1, opacity for scrolling transition
  navTheme?: NavTheme; // Nav color theme blue or white, defaults to white
  logoEast?: LogoTheme;
  logoTheme?: LogoTheme; // Logo color blue or white, defaults to blue
  buttonVariant: ButtonVariant;
  showSignIn?: boolean;
  pathname?: string;
}

const goToHomePage = () => (window.location.href = getUrlPathWithPreservedQuery('/'));

export const Header: FC<HeaderProps> = ({
  navTheme = NavTheme.white,
  variant = Variant.base,
  logoTheme = LogoTheme.blue,
  logoEast = LogoTheme.green,
  buttonVariant,
  navOpacity = 1,
  stickyTopOffset = 0,
  pathname = '',
  showSignIn = true,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const isHomepageBannerShown = false; // Place holder til we migrate the flag to Amplitude.
  const bannerText = '';

  useEffect(() => {
    setShowMenu(false);
  }, [pathname]);

  const handleOpenMenu = (e: MouseEvent) => {
    e.preventDefault();
    setShowMenu(true);

    track(EVENT.viewNavBar, { pagepath: window?.location?.pathname, is_logged_in: false });

    // There seems to be some sort of race condition between focus events when opening the
    // side menu, so without the timeout, the active element ends up being the menu button
    setTimeout(() => document.getElementById('close-menu')?.focus(), 500);
  };

  const handleCloseMenu = () => {
    setShowMenu(false);

    // There seems to be some sort of race condition between focus events when closing the
    // side menu, so without the timeout, the active element ends up being the close button
    setTimeout(() => document.getElementById('menu-button')?.focus(), 500);
  };

  const handleClick = () => {
    track(EVENT.headerGetStartedClicked, { isMobile: IS_MOBILE ? 'yes' : 'no' });
    onGetStartedClickHandleRedirect('Navigation Bar');
  };
  return (
    <HeaderWrapper navTheme={navTheme}>
      <Sticky topOffset={stickyTopOffset}>
        {({ style }: { style: { [key: string]: string | number } }) => (
          <NavWrapper
            style={{
              position: 'relative',
              opacity: navOpacity,
              ...style,
            }}
            navTheme={navTheme}
            logoTheme={logoTheme}
            logoEast={logoEast}
            data-testid={NAV_HEADER}
          >
            <DesktopLeftNav>
              {variant !== Variant.simple && (
                <>
                  <LogoContainer onClick={goToHomePage}>
                    <CapsuleIcon iconName="CapsuleLogo" type={'logo'} />
                  </LogoContainer>
                  <HeaderNavLink href={getUrlPathWithPreservedQuery('/how-it-works')}>How it works</HeaderNavLink>
                  <HeaderNavLink href={getUrlPathWithPreservedQuery('/about')}>About us</HeaderNavLink>
                  <HeaderNavLink href={getUrlPathWithPreservedQuery('/doctors')}>For doctors</HeaderNavLink>
                  <HeaderNavLink href={getUrlPathWithPreservedQuery('/questions')}>Questions</HeaderNavLink>
                </>
              )}
            </DesktopLeftNav>
            <DesktopRightNav>
              {showSignIn && <HeaderNavLink href="/auth/login">Sign in</HeaderNavLink>}
              {variant !== Variant.simple && (
                <Button variant={buttonVariant} onClick={handleClick}>
                  Get started
                </Button>
              )}
            </DesktopRightNav>
            <MobileLeftNav>
              <HamburgerContainer id="button-container" navTheme={navTheme}>
                <button
                  id="menu-button"
                  type="button"
                  onClick={handleOpenMenu}
                  aria-haspopup="menu"
                  aria-expanded={showMenu}
                  aria-controls="side-menu"
                >
                  <CapsuleIcon iconName="Hamburger" aria-label="Open navigation menu" />
                  <ScreenReaderOnly>Open website navigation</ScreenReaderOnly>
                </button>
              </HamburgerContainer>
              {!showMenu && (
                <LogoContainer onClick={goToHomePage}>
                  <CapsuleIcon iconName="CapsuleLogo" type={'logo'} />
                </LogoContainer>
              )}
            </MobileLeftNav>{' '}
          </NavWrapper>
        )}
      </Sticky>
      <SideMenu isShown={showMenu} onClose={handleCloseMenu} logoTo={getUrlPathWithPreservedQuery('/')} />

      {isHomepageBannerShown && (
        <Banner>
          <MarkdownCopy copy={bannerText} />
        </Banner>
      )}
    </HeaderWrapper>
  );
};
