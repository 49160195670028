import styled from 'styled-components';
import InlineSVG from 'svg-inline-react';

// This kinds sucks
export const StyledIcon = styled(InlineSVG)`
  svg {
    height: ${props => {
      if (props.type === 'appButton') {
        return '4.8rem';
      } else if (props.type === 'logo') {
        return '2.4rem';
      }
      return '2.4rem';
    }};
    width: ${props => {
      if (props.type === 'appButton') {
        return '15.2rem';
      } else if (props.type === 'logo') {
        return 'auto';
      }
      return '2.4rem';
    }};
    ${props => props.theme.mediaQueries.extraLargeDown} {
      width: ${props => {
        if (props.type === 'appButton') {
          return '100%';
        } else if (props.type === 'logo') {
          return 'auto';
        }
        return '2.4rem';
      }};
    }
  }
`;
