import styled from 'styled-components';

export const Link = styled.a`
  color: ${props => props.theme.colors.white};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;
