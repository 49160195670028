import type { ReactElement } from 'react';

import ReactMarkdown from 'react-markdown';

import { Link } from './styles';

interface LinkProps {
  href: string;
  children: ReactElement;
}

const customRenderer = {
  link: ({ href, children }: LinkProps) => (
    <Link href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </Link>
  ),
};

export const MarkdownCopy = ({ copy }: { copy?: string }) => {
  if (!copy) return null;

  return <ReactMarkdown renderers={customRenderer}>{copy}</ReactMarkdown>;
};
